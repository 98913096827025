import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  IoCloudDownload,
  IoCloudUpload,
  IoSearch,
  IoTrash,
  IoTrashBin,
} from 'react-icons/io5';
import { LuChevronDown } from 'react-icons/lu';
import { FaFilter } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';

import { isFeatureEnabled } from 'helpers';
import { Button, FastFilterSelect, Tag } from '_components/_core';
import { ContactFilters } from '_components/_shared';
import { TYPES_OPTIONS, TYPES_TAG_ICON, TYPES_ADD } from '../utilities/constants';

import {
  ActionBar,
  FiltersCount,
  StyledButton,
  StyledDropdownToggle,
  StyledSearchInput,
  TabListGroup,
  TabListGroupItem,
} from '../styles';

const countFilters = filters => {
  return Object.entries(filters).filter(([key, value]) => key && value).length;
};

const ContactListHeader = ({
  isLoading,
  selectedItems,
  textFilter,
  type,
  filteredRecipients,
  handleAddContact,
  handleExportContacts,
  setTextFilter,
  renderCheckbox,
  handleTabChange,
  handleChangeType,
  onDeleteContacts,
  selectedContact,
  setSelectedItems,
  setSelectedContact,
  handleCreatesSimpleContact,
  onFetchContacts,
  user,
  totalRecipients,
}) => {
  const history = useHistory();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});

  const hasActiveFilters = !isEmpty(selectedFilters);

  const handleOpenFilters = () => {
    setIsFilterOpen(true);
  };

  const handleFilter = useCallback(
    filters => {
      setSelectedFilters(filters);

      onFetchContacts(filters);
    },
    [onFetchContacts],
  );

  const foundContactsMessage = useMemo(() => {
    if (isLoading) return 'Carregando...';

    if (Number(totalRecipients) === 1) return '1 encontrado';

    if (Number(totalRecipients) === 0) return 'Nenhum encontrado';

    return `${Number(totalRecipients)} encontrados`;
  }, [isLoading, totalRecipients]);

  return (
    <div
      className="bg-white d-flex align-items-center flex-column"
      style={{
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      }}
    >
      <div className="d-flex align-items-center justify-content-between w-100 p-3">
        <h5 className="m-0">
          Lista de contatos <br />
          <small className="text-muted">{foundContactsMessage}</small>
        </h5>
        <div className="d-flex">
          <Dropdown as={ButtonGroup} style={{ border: 'none' }}>
            <Button
              onClick={handleAddContact}
              className="btn btn-sm d-flex justify-content-center align-items-center btn-success"
              variant="success-2"
              size="xs"
              style={{ fontSize: '13px' }}
            >
              <FaPlus className="mr-2" />
              Criar novo
            </Button>
            <StyledDropdownToggle split>
              <LuChevronDown />
            </StyledDropdownToggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={handleExportContacts}
              >
                <IoCloudDownload size="1.2em" className="mr-2" />
                Exportar
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => history.push('/importar-contatos')}
              >
                <IoCloudUpload size="1.2em" className="mr-2" />
                Importar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {isFeatureEnabled('contact_filters', user.flags) && (
            <StyledButton
              variant="inverse-dark"
              icon={<FaFilter size="1.2em" />}
              iconPosition="left"
              disabled={isLoading}
              onClick={handleOpenFilters}
              className="ml-2"
              size="sm"
            >
              Filtros
              {hasActiveFilters && countFilters(selectedFilters) > 0 && (
                <FiltersCount>{countFilters(selectedFilters)}</FiltersCount>
              )}
            </StyledButton>
          )}
        </div>
      </div>
      <div className="w-100 pl-3 pr-3">
        <div
          className="d-flex align-items-center justify-content-between w-100"
          style={{
            border: '1px solid #E8E7EA',
            borderRadius: '8px',
            backgroundColor: '#fff',
          }}
        >
          <IoSearch size="1.2em" className="ml-2" />
          <StyledSearchInput
            type="text"
            className="form-control"
            placeholder="Pesquisar contatos por nome"
            onChange={e => setTextFilter(e.target.value)}
            value={textFilter}
          />
          {textFilter && (
            <IoTrash
              className="text-danger mr-2"
              size="1.2em"
              onClick={() => setTextFilter('')}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
        </div>
      </div>
      {selectedItems.length > 0 && (
        <ActionBar className="mt-2">
          <>
            <div className="d-flex align-items-center">
              {renderCheckbox()}
              <p>
                <strong>{selectedItems.length}</strong> selecionados
              </p>
            </div>
            <FastFilterSelect
              triggerStyle={{
                '&:hover': {
                  backgroundColor: '#ffd !important',
                },
              }}
              menuStyle={{ minWidth: '280px' }}
              label="Ações"
              value={`${selectedItems.length} selecionados`}
              options={[
                {
                  icon: TYPES_TAG_ICON.CUSTOMER,
                  value: 'SET_CUSTOMER',
                  label: `Marcar ${selectedItems.length} como cliente`,
                },
                {
                  icon: TYPES_TAG_ICON.SUPPLIER,
                  value: 'SET_SUPPLIER',
                  label: `Marcar ${selectedItems.length} como fornecedor`,
                },
                {
                  icon: TYPES_TAG_ICON.EMPLOYEE,
                  value: 'SET_EMPLOYEE',
                  label: `Marcar ${selectedItems.length} como funcionário`,
                },
                {
                  icon: (
                    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant="danger">
                      <IoTrashBin size="1.2em" />
                    </Tag>
                  ),
                  value: 'DELETE',
                  label: `Excluir ${selectedItems.length} ${pluralize('item', selectedItems.length)}`,
                },
              ]}
              onChange={value => {
                switch (value) {
                  case 'DELETE': {
                    const params = {
                      ids: selectedItems,
                    };

                    onDeleteContacts(params, () => {
                      setSelectedItems([]);

                      if (selectedContact && selectedItems.includes(selectedContact.id)) {
                        setSelectedContact({});
                      }
                    });

                    break;
                  }
                  case 'SET_CUSTOMER': {
                    handleChangeType(selectedItems, 'CUSTOMER');
                    break;
                  }
                  case 'SET_SUPPLIER': {
                    handleChangeType(selectedItems, 'SUPPLIER');
                    break;
                  }
                  case 'SET_EMPLOYEE': {
                    handleChangeType(selectedItems, 'EMPLOYEE');
                    break;
                  }
                  default:
                    break;
                }
              }}
            />
          </>
        </ActionBar>
      )}
      {selectedItems.length === 0 && (
        <TabListGroup className="mt-2">
          {TYPES_OPTIONS.map(option => (
            <TabListGroupItem
              key={option.value || 'null'}
              active={type === option.value}
              action
              onClick={() => handleTabChange(option.value)}
            >
              {option.label}
            </TabListGroupItem>
          ))}
        </TabListGroup>
      )}
      {filteredRecipients.length === 0 && (
        <div className="bg-transparent d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
          <h6 className="">Nenhum contato encontrado</h6>
          <Button
            variant="success-2"
            className="mt-2"
            onClick={handleCreatesSimpleContact}
          >
            {textFilter ? `Adicionar "${textFilter}"` : `Adicionar ${TYPES_ADD[type]}`}
          </Button>
        </div>
      )}
      <ContactFilters
        isOpen={isFilterOpen}
        onToggle={() => setIsFilterOpen(!isFilterOpen)}
        onFilter={handleFilter}
      />
    </div>
  );
};

ContactListHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  textFilter: PropTypes.string.isRequired,
  type: PropTypes.string,
  filteredRecipients: PropTypes.array.isRequired,
  handleAddContact: PropTypes.func.isRequired,
  handleExportContacts: PropTypes.func.isRequired,
  setTextFilter: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleChangeType: PropTypes.func.isRequired,
  onDeleteContacts: PropTypes.func.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  setSelectedContact: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
  handleCreatesSimpleContact: PropTypes.func.isRequired,
  onFetchContacts: PropTypes.func.isRequired,
};

export default ContactListHeader;
